import styles from "./testimonials.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function Testimonials() {
    return (
        <div className={styles.topDiv}>
            <div className={styles.heading}>Testimonials</div>
            <div style={{ marginTop: "40px" }}>
                <Carousel
                    autoPlay="true"
                    infiniteLoop="true"
                    interval="5000"
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    // showThumbs={false}
                >
                    <div className={styles.content}>
                        <div
                            style={{ marginBottom: "20px" }}
                            className={styles.TestimonialCardText}
                        >
                            Kapil’s hard work and efficiency are commendable. A
                            hard-working and obedient intern is what everyone
                            wants. We were lucky to have him as our intern. In
                            conclusion, I’d like to reiterate my strong support
                            to Kapil as a punctual and sincere participant. I
                            would highly recommend him for further awaiting
                            opportunities.
                        </div>
                        <div
                            style={{
                                height: "3px",
                                width: "100px",
                                background: "#e22837",
                            }}
                            className={styles.TestimonialCardLine}
                        ></div>
                        <div
                            style={{ marginTop: "20px" }}
                            className={styles.TestimonialCardText}
                        >
                            <div>Pradeep Chaubey</div>
                            <div>Founder, EnR Consultancy Services</div>
                        </div>
                    </div>
                    {/* <div className={styles.content}>
                        <div
                            style={{ marginBottom: "20px" }}
                            className={styles.TestimonialCardText}
                        >
                            2 Kapil’s hard work and efficiency is commendable. A
                            hard working and obedient intern is what everyone
                            wants. We were lucky to have Kapil as our intern. In
                            conclusion, I’d like to reiterate my strong support
                            to Kapil as a punctual and sincere participant. I
                            would highly recommend Kapil for further awaiting
                            opportunities.
                        </div>
                        <div
                            style={{
                                height: "3px",
                                width: "100px",
                                background: "#e22837",
                            }}
                            className={styles.TestimonialCardLine}
                        ></div>
                        <div
                            style={{ marginTop: "20px" }}
                            className={styles.TestimonialCardText}
                        >
                            <div>Pradeep Chaubey</div>
                            <div>Founder, EnR Consultancy Services</div>
                        </div>
                    </div> */}
                </Carousel>
            </div>
        </div>
    );
}
