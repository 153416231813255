import "./App.css";
import LandingArea from "./components/landingArea";
// import MyProjects from "./components/myProjects";
// import AboutMe from "./components/aboutMe";
// import MySkills from "./components/mySkills";
// import Testimonials from "./components/testimonials";
// import GetInTouch from "./components/getInTouch";
// import Footer from "./components/footer";
import App2 from "./components/App2";
import Loader from "./components/loader/loader";
import React, { useState, useEffect } from "react";

function App() {
    const [loading, setLoading] = useState(false);

    const width = window.innerWidth;
    const timeOutToBeGiven = width > 1280 ? 3500 : 2500;

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, timeOutToBeGiven);
    }, []);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <LandingArea />
                    <App2 />
                </div>
            )}
        </div>
    );
}

export default App;
