import React from "react";
import MyProjects from "./myProjects";
import AboutMe from "./aboutMe";
import MySkills from "./mySkills";
import Testimonials from "./testimonials";
import GetInTouch from "./getInTouch";
import Footer from "./footer";

const App2 = () => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 3700);
    }, [show]);

    if (!show) return null;

    return (
        <div>
            <MyProjects />
            <AboutMe />
            <MySkills />
            <Testimonials />
            <GetInTouch />
            <Footer />
        </div>
    );
};

export default App2;
