import styles from "./loader.module.css";
import React from "react";
import Slide from "react-reveal/Slide";

const Box1 = () => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 1000);
    }, [show]);

    if (!show) return null;

    return (
        <Slide top>
            <div className={styles.box2}></div>
        </Slide>
    );
};

export default Box1;
