import styles from "./loader.module.css";
import React from "react";
import Slide from "react-reveal/Slide";

const Box1 = () => {
    const [show, setShow] = React.useState(false);

    const width = window.innerWidth;
    const timeOutToBeGiven = width > 1280 ? 2000 : 1000;

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, timeOutToBeGiven);
    }, [show]);

    if (!show) return null;

    return (
        <Slide top>
            <div className={styles.box4}></div>
        </Slide>
    );
};

export default Box1;
