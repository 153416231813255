import React from "react";
import styles from "./myProjectsCard.module.css";

export default function MyProjectsCard(props) {
    return (
        <div className={styles.cardClass}>
            <div
                className={styles.cardMainSection}
                style={{
                    backgroundImage: `url(${props.image})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    position: "relative",
                    opacity: "0.99",
                }}
            >
                <div
                    className={styles.cardNumber}
                    style={{ transform: "translate(0px, 0px)", opacity: 1 }}
                >
                    {props.number}
                </div>
                <div className={styles.cardContent}>
                    <div
                        className={styles.cardContentHeading}
                        style={{ transform: "translate(0px, 0px)", opacity: 1 }}
                    >
                        {props.name}
                    </div>
                    <div
                        className={styles.cardContentDescription}
                        style={{ transform: "translate(0px, 0px)", opacity: 1 }}
                    >
                        {props.description}
                    </div>
                    <div className={styles.cardContentButtons}>
                        {/* <a
                            href={props.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cardContentButton}
                            style={{
                                transform: "translate(0px, 0px)",
                                opacity: 1,
                            }}
                        >
                            COMING SOON
                        </a> */}
                        <div className={styles.cardContentButton2}>COMING SOON</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
