import React from "react";
import Box1 from "./box1";
import Box2 from "./box2";
import Box3 from "./box3";
import Box4 from "./box4";
import styles from "./loader.module.css";

export default function Loader() {
    return (
        <div className={styles.mainDiv}>
            <Box4 />
            <Box1 />
            <Box2 />
            <Box3 />
        </div>
    );
}
