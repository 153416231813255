import React from "react";
import styles from "./myProjects.module.css";
import MyProjectsCard from "./myProjectsCard";
import MyProjectsCard2 from "./myProjectsCard2";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IosdNsut from "../images/IosdNsut.jpg";
import ApnaMessenger from "../images/ApnaMessenger.jpg";
import dBank from "../images/dBank.png";
import Nescii from "../images/Nescii.png";
import Adopto from "../images/Adopto.png";


const UseStyles = makeStyles((theme) => ({
    // root: {
    //     flexGrow: 1,
    // },
    paper: {
        background: "transparent",
        // color: "white",
        border: "none",
        boxShadow: "none",
        // padding: theme.spacing(2),
        // textAlign: "center",
        // color: theme.palette.text.secondary,
    },
}));

export default function MyProjects() {
    const classes = UseStyles();
    return (
        <div className={styles.topDiv}>
            <div className={styles.Heading}>Latest Projects</div>
            <div className={styles.miniHeading}>
                Here are a few projects I've worked on recently. Want to know
                more?{" "}
                <a
                    href="mailto:kapil1104aggarwal@gmail.com"
                    style={{ color: "#e22837" }}
                    target="_blank"
                    rel="noreferrer"
                >
                    Email Me.
                </a>
            </div>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={0} sm={1}>
                        <Paper className={classes.paper}></Paper>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.paper}>
                            <MyProjectsCard
                                name={"Nescii"}
                                description={"Blogging Platform"}
                                number={"01"}
                                image={Nescii}
                                link={"https://nescii.com/"}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.paper}>
                            <MyProjectsCard
                                name={"dBank"}
                                description={"Decentralized Bank"}
                                number={"03"}
                                image={dBank}
                                link={
                                    "https://github.com/kapilaggarwal11/dBank"
                                }
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={0} sm={1}>
                        <Paper className={classes.paper}></Paper>
                    </Grid>
                    <Grid item xs={0} sm={1}>
                        <Paper className={classes.paper}></Paper>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.paper}>
                            <MyProjectsCard
                                name={"Apna Messenger"}
                                description={"Messaging App"}
                                number={"04"}
                                image={ApnaMessenger}
                                link={
                                    "https://github.com/kapilaggarwal11/apna_messenger"
                                }
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.paper}>
                            <MyProjectsCard2
                                name={"Adopto"}
                                description={"Pet adoption platform"}
                                number={"04"}
                                image={Adopto}
                                link={
                                    "#"
                                }
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={0} sm={1}>
                        <Paper className={classes.paper}></Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper
                            className={classes.paper}
                            style={{ margin: "50px" }}
                        ></Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
