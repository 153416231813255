import styles from "./getInTouch.module.css";

export default function GetInTouch() {
    return (
        <div className={styles.topDiv}>
            <div className={styles.heading}>Get In Touch</div>
            <div className={styles.miniHeading}>
                Let’s make something together, start by saying hi!
            </div>

            <div className={styles.content}>
                <form
                    className={styles.form}
                    method="POST"
                    action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSffJIDzmGTRS94y7tDWa8AnhYYM-X-rLaXv7kMN4U7sj3fqrw/formResponse"
                >
                    <div className={styles.inputField}>
                        <input name="entry.155420651" type="text" required />
                        <label for="name">Name</label>
                    </div>
                    <div className={styles.inputField}>
                        <input name="entry.1405434513" type="text" required />
                        <label for="email">Email</label>
                    </div>
                    <div className={styles.inputField}>
                        <textarea name="entry.1060942044" rows="3" required />
                        <label for="message">Message</label>
                    </div>
                    <div className={styles.buttons}>
                        <button className={styles.button} type="submit">
                            Send Message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
